import service from "../util/link"
import url from "../util/apiurl"


/*历史报警请求*/
export function history() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.history,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function pages(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.history,
            method: "get",
            params: {
                page: obj
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function historyInfo(codeid, startTime, endTime, type, page, alarm) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.history,
            method: "get",
            params: {
                codeid: (typeof (codeid) == 'number') || (typeof (codeid) == 'string') ? codeid : "",
                startTime: (typeof (startTime) == 'number') || (typeof (startTime) == 'string') ? startTime : "",
                endTime: (typeof (endTime) == 'number') || (typeof (endTime) == 'string') ? endTime : "",
                type: (typeof (type) == 'number') || (typeof (type) == 'string') ? type : "",
                page: (typeof (page) == 'number') || (typeof (page) == 'string') ? page : "",
                alarm: alarm ? 1 : ""
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function playvideo(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.checkvideo,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}