<template>
  <div class="historytable">
    <el-row :gutter="0" class="search" type="flex" align="middle">
      <el-col class="search_item" :span="2">设备编号:</el-col>
      <el-col :span="3" class="search_item">
        <el-select
          v-if="userRoleId === '0'"
          v-model="id"
          clearable
          placeholder="请选择设备"
          @change="userselecteqm($event)"
        >
          <el-option
            v-for="item in useroptions"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-cascader
          v-if="userRoleId === '1' || userRoleId === '2'"
          v-model="defaultval"
          :options="options"
          @change="handleChange($event)"
          :props="optionProps"
          clearable
        ></el-cascader
      ></el-col>
      <el-col :span="1" class="search_item">时间:</el-col>
      <el-col :span="6" class="search_item">
        <el-date-picker
          v-model="value2"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker
      ></el-col>
      <el-col :span="2" class="search_item">报警类型:</el-col>
      <el-col :span="3" class="search_item"
        ><el-select v-model="typevalue" clearable placeholder="请选择报警类型">
          <el-option label="近距离" value="0"></el-option>
          <el-option label="远距离" value="1"></el-option> </el-select
      ></el-col>
      <el-col :span="1" :offset="1" class="search_item">
        <el-checkbox v-model="alarmcheck">预警视频</el-checkbox>
      </el-col>
      <el-col :span="1" :offset="4" class="search_item"
        ><el-button
          type="primary"
          style="backgroundcolor: #0076f6"
          @click="searchHistory()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <el-row>
      <el-table
        v-loading="loading"
        :element-loading-text="loadingtext"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        border
        align="center"
        stripe
        style="color: #161718"
        max-height="700"
        class="tabledata"
      >
        <el-table-column
          v-if="userRoleId === '1' || userRoleId === '2'"
          prop="company"
          label="公司"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="code" label="设备编号" align="center">
        </el-table-column>
        <el-table-column prop="location" label="位置" align="center">
        </el-table-column>
        <el-table-column prop="road_name" label="道路编号" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.road_name == '' || scope.row.road_name == null"
              >--</span
            >
            <span v-else>{{ scope.row.road_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mileage" label="桩号" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.mileage == '' || scope.row.mileage == null"
              >--</span
            >

            <span v-else>{{ scope.row.mileage }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="toward" label="去向" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.toward == '' || scope.row.toward == null"
              >--</span
            >

            <span v-else>{{ scope.row.toward }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upordown" label="施工内容" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.upordown !== ''">{{
              scope.row.upordown
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <!-- <el-table-column v-if="userRoleId === '1'" prop="lnglat" label="经纬度" width="180" align="center">
      </el-table-column> -->
        <el-table-column prop="time" label="时间" align="center">
        </el-table-column>
        <el-table-column prop="type" label="报警类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1" style="">远距离报警</span>
            <span v-else style="">近距离报警</span>
          </template>
        </el-table-column>
        <el-table-column prop="distance" label="报警距离" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.distance }}m</span>
          </template>
        </el-table-column>
        <el-table-column prop="speed" label="报警速度" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.speed }}Km/h</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="video"
          label="查看视频"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              style="backgroundcolor: #0076f6"
              v-if="scope.row.video != null && scope.row.localvideo == '1'"
              @click="checkvideo(scope.row)"
              type="primary"
              size="small"
              >查看</el-button
            >
            <el-button
              v-else-if="
                scope.row.video != null &&
                scope.row.status == '1' &&
                scope.row.localvideo == '0'
              "
              @click="checkvideo(scope.row)"
              type="primary"
              size="mini"
              >上传并查看</el-button
            >
            <el-button
              v-else-if="
                scope.row.video != null &&
                scope.row.status == '0' &&
                scope.row.localvideo == '0'
              "
              disabled
              @click="checkvideo(scope.row)"
              type="primary"
              size="mini"
              >上传并查看</el-button
            >
            <span v-else style="">无</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="paging" align="middle" type="flex" justify="end">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev,total, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
    <div class="playVideo drag" v-if="showvideo == true" v-draw>
      <el-link
        v-show="showvideo == true"
        type="primary"
        icon="el-icon-close"
        @click="playClose()"
      ></el-link>
      <video
        v-show="showvideo == true"
        class="play"
        controls
        autoplay
        loop
        preload="auto"
        :src="videosrc"
        poster="../../../public/img/loading.gif"
        id="videoPlay"
      >
        <!-- <source :src="videosrc" type="video/mp4" /> -->
      </video>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  history,
  pages,
  historyInfo,
  playvideo,
} from "../../api/alarmhistryapi";
import Play from "../../components/Play";

export default {
  components: {
    Play,
  },
  data() {
    return {
      isVideo: false,
      alarmcheck: false,
      userRoleId: "",
      loadingtext: "正在加载。。。。",
      loading: false,
      showvideo: false,
      videosrc: " ",
      isplay: false /**显示视频 */,
      idcode: this.$route.params.code,
      companyid: this.$route.params.companyid,
      selectcompanyid: null,
      useroptions: [
        {
          id: "",
          text: "",
        },
      ],
      id: "",
      /**级联选择设备 */
      defaultval: [],
      options: [],
      id: null,
      optionProps: {
        checkStrictly: false,
        value: "id",
        label: "text",
        children: "children",
      },
      eqmid: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value2: "",
      typevalue: "",
      tableData: [],
      currentPage3: null /**分页 */,
      pagesize: 12,
      total: 32,
      nowpage: null,
    };
  },
  created() {
    this.userRoleId = this.$cookies.get("userRoleId");
  },
  mounted() {
    console.log(this.idcode);
    console.log(this.companyid);
    console.log("这是传过来的");
    this.loading = true;
    let selectcode = this.idcode;
    if (selectcode) {
      this.frommapdata();
      if (this.userRoleId == "1") {
        this.defaultval = [this.companyid, this.idcode];
      }
      this.eqmid = this.idcode;
      selectcode = "";
    } else {
      this.gethistory();
    }
  },
  methods: {
    /**接收indexmap页数据查询报警信息 */
    frommapdata() {
      if (this.idcode != "") {
        console.log(this.value2, "这是日期");

        historyInfo(this.idcode, this.value2[0], this.value2[1], this.typevalue)
          .then((res) => {
            if (res.status == 200) {
              this.useroptions = res.data.casecade[0].children;
              this.selectcompanyid = res.data.casecade[0].id;
              this.options = res.data.casecade;
              this.tableData = res.data.histories;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
              this.loading = false;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
      }
    },

    /**获取历史报警数据 */
    gethistory() {
      history()
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            console.log(res);
            console.log("历史数据");
            console.log(res.data.casecade);
            console.log(
              res.data.casecade,
              res.data.casecade[0].id,
              res.data.casecade[0].children,
              "数据11111111111"
            );
            this.useroptions = res.data.casecade[0].children;
            this.selectcompanyid = res.data.casecade[0].id;
            this.options = res.data.casecade;
            this.tableData = res.data.histories;
            this.pagesize = res.data.page_size;
            this.total = res.data.total_pages;
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    /**级联选择设备
     */
    userselecteqm(e) {
      console.log(e, "用户用户");
      this.eqmid = e;
    },
    handleChange(e) {
      console.log(e);
      this.eqmid = e[1];
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    /**分页切换下一页*/
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.nowpage = val;
      if (this.value2) {
        console.log(this.value2, "啥2");
        historyInfo(
          this.eqmid,
          this.value2[0],
          this.value2[1],
          this.typevalue,
          val,
          this.alarmcheck
        )
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.histories;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        console.log(this.eqmid, "这是code");
        historyInfo(this.eqmid, "", "", this.typevalue, val, this.alarmcheck)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.histories;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })

          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
    /**历史报警查询 */
    searchHistory() {
      // this.currentPage3 = 1;
      console.log(this.currentPage3, "---");
      if (this.value2 && this.alarmcheck) {
        console.log(this.value2, "啥2");
        historyInfo(
          this.eqmid,
          this.value2[0],
          this.value2[1],
          this.typevalue,
          "",
          this.alarmcheck,
          this.nowpage
        )
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.histories;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        console.log(this.eqmid, "这是code");
        historyInfo(
          this.eqmid,
          "",
          "",
          this.typevalue,
          "",
          this.alarmcheck,
          this.nowpage
        )
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.histories;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })

          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
    /**查看历史报警视频 */
    checkvideo(e) {
      console.log(e, "字段");
      console.log(e.video);
      this.videosrc = "";
      if (e.localvideo == "1") {
        var param = new URLSearchParams();
        param.append("code", e.code);
        param.append("vod_file", e.video);
        playvideo(param)
          .then((res) => {
            if (res.data.code == 200) {
              this.videosrc = "https://www.jass1688.com/" + e.video;
              this.showvideo = true;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else if (e.status == "1" && e.localvideo == "0") {
        this.showvideo = true;
        var param = new URLSearchParams();
        param.append("code", e.code);
        param.append("vod_file", e.video);
        playvideo(param)
          .then((res) => {
            if (res.data.code == 200) {
              this.isVideo = true;
              setTimeout(() => {
                this.videosrc = "https://www.jass1688.com/" + e.video;
                let src = e.video;
                let Media = document.getElementById("videoPlay");
                var eventTester = (e) => {
                  Media.addEventListener(
                    e,
                    (err) => {
                      if (err && this.isVideo) {
                        setTimeout(() => {
                          this.videosrc = "https://www.jass1688.com/" + src;
                          Media.load();
                        }, 2000);
                      }
                    },
                    false
                  );
                };
                eventTester("error");
              }, 5000);
            }
          })
          .catch((e) => {
            this.$message.error(e);
          });
      } else {
        this.loading = false;
      }
    },
    playClose() {
      this.showvideo = false;
      this.isVideo = false;
      if (this.value2 && this.alarmcheck) {
        console.log(this.value2, "啥2");
        historyInfo(
          this.eqmid,
          this.value2[0],
          this.value2[1],
          this.typevalue,
          this.currentPage3,
          this.alarmcheck
        )
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.histories;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      } else {
        console.log(this.eqmid, "这是code");
        historyInfo(
          this.eqmid,
          "",
          "",
          this.typevalue,
          this.currentPage3,
          this.alarmcheck
        )
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.histories;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })

          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
    // tableRowClassName({ row, rowIndex }) {
    //   console.log(row.video)
    //   if (row.type == 0) {
    //     return { "background-color": "#f56c6c" };
    //   }
    //   return "";
    // },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1920px) {
  .historytable {
    /* background-color: cornflowerblue; */
    padding: 1.25rem 1.875rem 0 1.875rem;
  }
  .paging {
    width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }
  .search_item {
    /* background-color: darkcyan; */
  }
  .search {
    padding: 0.5rem 0.625rem 0.5rem 0.625rem;
    font-size: 0.875rem;
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    border-radius: 0.625rem;

    color: #161718;
    text-align: center;
  }
  .tabledata {
    margin-top: 1.25rem;
  }
  .drag {
    top: 12.5rem;
    left: 31.25rem;
  }

  .playVideo {
    width: 50rem;
    height: 28.125rem;
    position: relative;
    top: 200;
    left: 200;
  }
  .play {
    width: 50rem;
    height: 28.125rem;
    border: 0.0625rem solid black;
    background-color: black;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .el-link {
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 10;
    background-color: black;
  }
}
@media only screen and (max-width: 1080px) {
  .historytable {
    /* background-color: cornflowerblue; */
    padding: 0.625rem 0.625rem 0 0.625rem;
  }
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    width: 13rem !important;
  }
  .paging {
    width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }
  .search_item {
    /* background-color: darkcyan; */
  }
  .search {
    padding: 0.5rem 0.625rem 0.5rem 0.625rem;
    font-size: 0.875rem;
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    border-radius: 0.625rem;

    color: #161718;
    text-align: center;
  }
  .tabledata {
    margin-top: 1.25rem;
  }
  .drag {
    top: 15.625rem;
    left: 18.75rem;
  }

  .playVideo {
    width: 25rem;
    height: 15.625rem;
    position: relative;
    top: 100;
    left: 100;
  }
  .play {
    width: 25rem;
    height: 15.625rem;
    border: 0.0625rem solid black;
    background-color: black;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .el-link {
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    z-index: 10;
    background-color: black;
  }
}
</style>